// Perhaps need a replaceAll replacement

import {
  freeResponseOperators,
  genOperators,
  scaleOperators,
} from "components/QuestionConstruction/QuestionLogic/QuestionLogic";
import { isConditionComplete } from "./ObjectFunctions";

/**
 *
 * @param {*} string
 * @returns Returns a boolean of whether the email is valid or not
 */
export function validateEmail(e) {
  var filter =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return e && String(e).search(filter) != -1;
}

/**
 *
 * @param {*} string
 * @returns Returns a string with the first letter captialized
 */
export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function trim(text, chars = 20) {
  if (!text) return "";
  if (text.length > 20) {
    let part = text.substring(0, chars);
    return part + "...";
  } else {
    return text;
  }
}

function getCondition(condition, question, groupOperator = "") {
  const allOperators = [
    ...genOperators,
    ...scaleOperators,
    ...freeResponseOperators,
  ];

  const getOperator = () => {
    if (condition.operator !== "answer") {
      if (scaleOperators.some((op) => op.value === condition.operator))
        return condition.operator;

      if (
        condition.operator === "have answered" ||
        condition.operator === "have not answered"
      ) {
        let op = freeResponseOperators.find(
          (op) => op.value === condition.operator
        );
        if (op) return op.label;
      }

      let op = allOperators.find((op) => op.value === condition.operator);
      if (op) return op.label;
    }
    return "";
  };

  const getAnswer = () => {
    if (!condition.answer) return "";
    if (Array.isArray(condition.answer)) {
      let string = "";
      for (let i = 0; i < condition.answer.length; i++) {
        string += condition.answer[i];
        if (i < condition.answer.length - 1) {
          if (i < condition.answer.length - 2) {
            string += ", ";
          } else {
            string += condition.operator === "between" ? " and " : " or ";
          }
        }
      }
      return string;
    }

    if (question.textQuestion) {
      if (
        condition.operator === "is longer than" ||
        condition.operator === "is shorter than"
      ) {
        return condition.answer + " characters";
      }
      if (condition.operator.includes("contain")) {
        return `"${condition.answer}"`;
      }
    }

    return condition.answer;
  };

  return `${
    groupOperator.includes("no") ? "not " : ""
  } ${getOperator()} ${getAnswer()}`;
}

export function conditionDescription(
  condition,
  dependentQ,
  dependentIndex,
  groupOperator
) {
  if (!isConditionComplete(condition)) {
    return "";
  }

  return `Q${dependentIndex + 1}${
    "option" in condition ? ` - ${condition.option}` : ""
  }: ${getCondition(condition, dependentQ, groupOperator)}`;
}

/**
 *
 * @param {*} condition condition
 * @param {*} question question the condition(s) apply to
 * @param {*} dependents string[]
 */
export function appliedConditionDescription(condition, question, dependents) {
  if (!isConditionComplete(condition)) {
    return "";
  }

  function listDependents() {
    let string = "";
    for (let d of dependents) {
      if (string) string += ", ";
      string += d;
    }
    return string;
  }

  return `${getCondition(condition, question)}: ${listDependents()}`;
}

export function moveToFirst(arr, index) {
  if (index >= 0 && index < arr.length) {
    const [item] = arr.splice(index, 1); // Remove the item from its current position
    arr.unshift(item); // Add the item to the beginning of the array
  }
  return arr;
}

export function isValidEmail(val) {
  if (!val.includes(" ")) {
    let atInd = val.indexOf("@");
    if (atInd >= 1) {
      //can't start with @ either
      let substring = val.substring(atInd);
      let dotInd = substring.indexOf(".");
      if (dotInd >= 2) {
        // can't go @.
        let last = substring.substring(dotInd + 1);
        if (last.length) {
          return true;
        }
      }
    }
  }
  return false;
}


export function isValidDomain(domain) {
  const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,}$/;
  return domainRegex.test(domain);
}